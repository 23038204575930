import React,{useEffect,useState} from 'react'
import { useScore } from '../contexts/ScoreContext'
import { StyleDiv } from '../styled/GameOver'
import { StyleLink } from '../styled/StyledNavbar';
import {Accent, StyleTitle} from '../styled/Random'
import { useAuth0 } from '@auth0/auth0-react';

export default function GameOver({history}) {
    const {user,isAuthenticated,getAccessTokenSilently} = useAuth0();
    const [score] = useScore();
    const [scoreMessage, setScoreMessage] = useState('')
    if(score === -1){
        history.push("/");

    }
    useEffect(() => {
        const save = async() =>{
            try{
                const token = await getAccessTokenSilently();
                const options = {
                    method:"POST",
                    body:JSON.stringify({Name:user.name,Score:score}),
                    headers: {Authorization:`Bearer ${token}`}
                }
                const res = await fetch('/.netlify/functions/saveHighScores',options)
                const data = await res.json();
                console.log(data)
                if(data.id)
                {
                    setScoreMessage("You have made the highscore board!")
                }
                else
                {
                    setScoreMessage("Not a highscore! Keep Trying!")
                }

            }
            catch(err)
            {
                console.error(err)
            }
            } 
    if(isAuthenticated){
        save();
    }
        
    }, [getAccessTokenSilently,isAuthenticated,score,user])

    return (
        <StyleDiv>
           <StyleTitle>Game Over!</StyleTitle>
           {!isAuthenticated && 
           <p>You should log in to compete for high scores!</p>}
            <StyleDiv>
            <Accent> {scoreMessage}</Accent> 
            </StyleDiv>
            <StyleDiv>
            Your Score :  <Accent> {score} </Accent> 
            </StyleDiv>
            <StyleDiv></StyleDiv>
            <StyleLink to="/">Go Home</StyleLink>
            <StyleDiv></StyleDiv>
            <StyleLink to="/game">Play Again</StyleLink>
        </StyleDiv>
        
    )
}
