import styled from "styled-components";

export const StyleDiv = styled.div`

height:75vh;
max-height:500px;
display: grid;
grid-template-rows:50 px 1fr;
grid-template-columns: minmax(50px,auto) 1fr minmax(50px,auto);

`

export const StyleScore = styled.p`

font-size:1.5rem;
`

export const StyleTimer = styled.p`

font-size:1.5rem;
grid-column:3;
`

export const StyleRandomChar = styled.p`

font-size:10rem;
text-align:center;
grid-row:2;
grid-column:1/4;
color:var(--accent-color);
`