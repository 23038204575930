
import styled from "styled-components";


export const StyleDiv = styled.div`

font-size:1.5rem;
text-align:center;

`

export const StyleItems = styled.ol`

padding-left:0;
list-style:none;


`
export const Styleli = styled.li`

padding-top: 1rem;


`
