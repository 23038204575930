import React,{useState,useEffect} from "react";
import { StyleDiv,StyleItems,Styleli } from "../styled/HighScores";

export default function HighScores()
{
    // use the fetch API to call getHighScores function
    //display score
    const [highScores,setHS] = useState([])

    useEffect( () => {
        
        const loadHighScores = async() => {
            try {
                const res = await fetch('/.netlify/functions/getHighScores')
                const scores = await res.json()
                setHS(scores)
            } catch (err) {
                console.error(err)
            }
        }
        loadHighScores();

    }, [])

    return(
       <StyleDiv>
           <h1>High Scores</h1>
           <StyleItems>
               {highScores.map((score,index) => (
                <Styleli key={score.id}>
                    {index + 1}. {score.fields.Name} - {score.fields.Score}</Styleli>
               ))}
           </StyleItems>
       </StyleDiv>
    )
}