import React from "react";
import CTA from "../styled/CTA";
import { Accent, StyleTitle } from "../styled/Random";


export default function Home()
{
    
      return(
        <div>
            <StyleTitle>Get Ready to <Accent>Play</Accent>!</StyleTitle>
            <CTA to="/game">Click to start!</CTA>
        </div>
    )
}