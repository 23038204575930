import React from 'react'
import { StyleNavbar,StyleNavLogo,StyleNavItems,StyleLink} from '../styled/StyledNavbar'
import { Accent } from '../styled/Random'
import LoginButton from './LoginButton'
import LogoutButton from './LogoutButton'
import Profile from './profile'
import { useAuth0 } from '@auth0/auth0-react'


export default function Navbar() {
    const {isLoading } = useAuth0();

    if (isLoading) {
      return <div>Loading ...</div>;
    }
    return (
        <StyleNavbar>
        <StyleNavLogo>
                <StyleLink to="/">
                    Brian's <Accent>Game</Accent> 
                </StyleLink>
        </StyleNavLogo>
            <StyleNavItems>
                <li><StyleLink to="/">
                    Home
                    </StyleLink>
                </li>
                <li><StyleLink to="/highScores"> 
                    High Scores
                    </StyleLink>
                </li>
                <li>
                <Profile/>
                <LoginButton/>
                <LogoutButton/>
                
                </li>
            </StyleNavItems>
        </StyleNavbar>
    )
}
