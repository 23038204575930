import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyleNavbar = styled.nav`

display:grid;
grid-template-columns:1fr auto;
padding: 20px;

`

export const StyleNavLogo = styled.div`

font-size:1.5rem;
margin-right:2rem;
& > a {
    text-decoration:none;
}

`

export const StyleNavItems = styled.ul`

list-style:none;
padding-left:0;
display:grid;
grid-auto-flow: column;
grid-gap:20px;


`

export const StyleLink = styled(Link)`

text-decoration:none;
font-size:1.2rem;
transition: color 200ms;
&:hover{
    color:var(--accent-color);
}

`

export const StyleButton = styled.button`

border:none;
font-size:1.2rem;
cursor: pointer;
background-color: var(--accent-color);
color: var(--main-bg-color);
text-align:center;
`

