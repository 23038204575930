import { Link } from "react-router-dom";
import styled from "styled-components";

export default styled(Link)`
text-align:center;
font-size: 1.5rem;
display:block;
text-decoration:none;

&:hover{
    text-decoration:underline;
}

`