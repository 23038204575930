import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {StyleButton} from '../styled/StyledNavbar'


const LoginButton = () => {
  const { loginWithRedirect,isAuthenticated } = useAuth0();

  return  !isAuthenticated && (
  <StyleButton onClick={() => loginWithRedirect()}>Log In</StyleButton>
  )
};

export default LoginButton;