import React from 'react';

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import Game from './Pages/Game';
import GameOver from './Pages/GameOver';
import Home from './Pages/Home';
import HighScores from './Pages/HighScores';
import Navbar from './components/Navbar';
import { Container } from './styled/Container';
import { Main } from './styled/Main';
import { GlobalStyle } from './styled/Global';


function App() {
  
  return (
    <Router>
      <GlobalStyle/>
      <Main>
      <Container>
        <Navbar/>
        <Switch>  
          <Route path="/game" component={Game}/>
          <Route path="/highScores" component={HighScores}/>
          <Route path="/gameOver" component={GameOver}/>
          <Route path="/" component={Home}/>
        </Switch>
      </Container>
      </Main>
    </Router>
  );
}

export default App;
