import styled from "styled-components";

export const Accent = styled.span`
color:var(--accent-color);
`
export const StyleTitle = styled.h1`

font-size:3rem;
text-align:center;
margin-bottom:2rem;
margin-top:2rem;
`

export const Bold = styled.strong`
font-weight: bold;
`