import React,{useState,useEffect, useCallback} from "react";
import { useScore } from "../contexts/ScoreContext";
import { StyleDiv,StyleScore,StyleTimer,StyleRandomChar } from "../styled/Game";
import { Bold } from "../styled/Random";

export default function Game ({history}){
// eslint-disable-next-line
   const [score, setScore] = useScore(0);
    const MAX_SEC = 5;
    const [Ms, setMs] = useState(999);
    const [seconds, setseconds] = useState(MAX_SEC);
    const [randomChar, setrandomChar] = useState('')

    //Only run once
   useEffect(() => {
    setrandomChar(getRandomChar)
    setScore(0);
    const currentTime = new Date();
    const interval = setInterval(() => updateTime(currentTime),1);
    return () => clearInterval(interval)
    // eslint-disable-next-line
   },[]);

   const updateTime = (startTime) => {
    const endDate = new Date();
    const msPassedStr = (endDate.getTime()-startTime.getTime()).toString();
    const formattedMSString = ('0000' + msPassedStr).slice(-5);
    const updatedSeconds = MAX_SEC - parseInt(formattedMSString.substring(0,2))- 1;
    const updatesMs = 1000 - parseInt(formattedMSString.substring(formattedMSString.length-3));
    setseconds(addLeadingZeros(updatedSeconds,2));
    setMs(addLeadingZeros(updatesMs,3));
   }

   const addLeadingZeros = (num,length) => {
    let zeros = '';
    for (let i=0;i<length;i++){
        zeros += "0"
    }
    return (
        zeros +num
    ).slice(-length);

   }

   useEffect(() => {
       if(seconds <= -1)
       {
           //todo: Save Score
           history.push('/gameover')
       }
       
   }, [seconds,Ms,history])

   const getRandomChar = () =>
   {
    return String.fromCharCode(65+Math.floor(Math.random() * 26))
   }
 

   const keyUpHandler = useCallback((e) => {
        if(e.key.toUpperCase() === randomChar)
       {
           //setScore(score + 1);
           setScore((prevScore) => prevScore + 1)
       }
       else
       {
           if(score>0){
               setScore((prevScore) => prevScore - 1)
           }
       }
       setrandomChar(getRandomChar)
       // eslint-disable-next-line
   },[randomChar]);

   useEffect(() => {
       document.addEventListener('keyup', keyUpHandler)
       return () => {
           document.removeEventListener('keyup',keyUpHandler);
       }
   }, [keyUpHandler])

   
   
   return (
        <StyleDiv>
            <StyleScore>Score: <Bold>{score}</Bold></StyleScore>
            <StyleRandomChar>{randomChar}</StyleRandomChar>
            <StyleTimer>Time: <Bold>{seconds}: {Ms}</Bold></StyleTimer>

        </StyleDiv>
    )
}