import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {StyleButton} from '../styled/StyledNavbar'


const LogoutButton = () => {
  const { logout, isAuthenticated } = useAuth0();

  return (
    isAuthenticated && (
   <StyleButton onClick={() => logout({ returnTo: window.location.origin })}>
      Log Out
    </StyleButton>
  ))
};

export default LogoutButton;